<script>

</script>


<template>
    <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
         <div class="pt-lg-md" style="margin-top: 60px;">
                <div class="row justify-content-center">
                    <div class="col-lg-11">
                        <!-- <card type="secondary" shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                            class="border-0"> -->
                            <h1 class="heading">Membership List</h1>
                            <div class="table-responsive" id="mainDiv" style="min-height: 60vh;">

                              <ejs-grid ref='membershipList' id='membershipList' :dataSource="memberList" :allowFiltering='true' :filterSettings='filterSettings' :allowSorting='true' :allowPdfExport='true'  :allowExcelExport='true'
                                :toolbar='toolbar' :pdfExportComplete='pdfExportComplete' :allowPaging="true" :pageSettings='pageSettings' :rowDataBound='rowDataBound' :toolbarClick='toolbarClick'  :enableHover='false' :enableHeaderFocus='true' :autoFit='true'>
                                  <e-columns>
                                      <e-column field='fname' headerText='First Name'></e-column>
                                      <e-column field='lname' headerText='Last Name'></e-column>
                                      <e-column field='email' headerText='Email'></e-column>
                                      <e-column field='telephone' headerText='Phone Number'></e-column>

                                      <e-column field='member_id' headerText='Member Id'></e-column>
                                      <e-column field='spouse' headerText='Spouse Name'></e-column>
                                      <e-column field='spouseemail' headerText='Spouse Email'></e-column>
                                      <e-column field='status' headerText='Status'></e-column>
                                      <e-column field='type' headerText='Type'></e-column>
                                      <e-column field='year' headerText='Year'  textAlign='Right'></e-column>
                                  </e-columns>
                              </ejs-grid>
                               
                                <!-- <v-table class="vehicle-table table table-striped align-middle" :data="memberList" :filters="filters" :currentPage.sync="currentPage" 
                                  :pageSize="10"  @totalPagesChanged="totalPages = $event">
                                  <thead slot="head">
                                    <tr>
                                      <v-th sortKey="firstName">First Name</v-th>
                                      <v-th sortKey="lastName">Last Name</v-th>
                                      <v-th sortKey="email">Email</v-th>
                                      <v-th sortKey="phone">Phone Number</v-th>
                                      <th>Adults</th>
                                      <th>Kids</th>
                                      <v-th sortKey="rsvpName">RSVP</v-th>
                                      <th>Paypal ID</th>
                                      <th>Paid Amount</th>
                                      <th>Paid Status</th>
                                    </tr>
                                  </thead>
                                  <tbody slot="body" slot-scope="{displayData}">
                                    <tr v-for="rsvpReg in displayData" :key="rsvpReg.rsvpRegId" :style="getStyle(rsvpReg)">
                                      <td>{{ rsvpReg.firstName }}</td>
                                      <td>{{ rsvpReg.lastName }}</td>
                                      <td>{{ rsvpReg.email }}</td>
                                      <td>{{ rsvpReg.phoneNumber }}</td>
                                      <td>{{ rsvpReg.adults }}</td>
                                      <td>{{ rsvpReg.kids }}</td>
                                      <td>{{ rsvpReg.rsvpName }}</td>
                                      <td>{{ rsvpReg.paypalId }}</td>
                                      <td>{{ rsvpReg.paidAmount }}</td>
                                      <td>{{ rsvpReg.paidStatus }}</td>
                                    </tr>
                                  </tbody>
                                </v-table>
                                <smart-pagination :currentPage.sync="currentPage" :totalPages="totalPages"/> -->
                            </div>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>
import firebase from '../Firebase.js';
import { GridPlugin, VirtualScroll, Sort, GridComponent, Column, ColumnsDirective, PdfExport, ExcelExport, Page, Toolbar, Filter, FilterType } from "@syncfusion/ej2-vue-grids";
// import "../../node_modules/@syncfusion/ej2/bootstrap5.css"

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');

export default {
  components: {
    'ejs-grid' : GridComponent,
    'e-columns' : ColumnsDirective,
    'e-column' : ColumnsDirective,
  },
  data() {
    return {
      theme: theme,
      pageSettings: { pageCount: 10},
      toolbar: ['ExcelExport', 'PdfExport'],
      tooltip: {
        enable: true,
      },
      zoom:
        {
            enableMouseWheelZooming: true,
            enablePinchZooming: true,
            enableSelectionZooming: true
        },
      legend: {enableHighlight : true, position: 'Top'},
      filterSettings: { type: 'Menu' },
      filters: {
        name: { value: '', keys: ['firstName', 'lastName'] },
        email: { value: '', keys: ['email'] },
        phone: { value: '', keys: ['phoneNumber'] },
        rsvp: { value: '', keys: ['rsvpName'] },
        
      },
      memberList: [],
      currentPage:1,
      totalPages:0,
      records:[],
      rsvpList:[],
      selectedRSVPName:'',
      selectedRSVPType:'',
      rsvpId:'',
      showfilters: false,
    };
  },
  provide: {
    grid: [Sort, VirtualScroll, PdfExport, ExcelExport, Page, Toolbar, Filter]
  },
  mounted() {
    // Fetch the RSVP registered list data from an API or any other source
    // and assign it to the 'memberList' property
    // this.loadRsvps();
    this.fetchMemberList();
  },
  methods: {
    async loadRsvps(){

        const db = firebase.firestore();

        await db.collection('rsvp')
          .get()
          .then(docs => {
              docs.forEach(doc => {
                  const rsvp = {
                      rsvpId: doc.id,
                      ...doc.data()
                  };
                  this.rsvpList.push(rsvp);
                  if(rsvp.active && this.rsvpId == ''){
                      this.rsvpId = rsvp.rsvpId;
                      this.selectedRSVPName = rsvp.name;
                      this.selectedRSVPType = rsvp.RSVPType;
                      this.fetchMemberList();
                  }
              });
          })
          .catch(error => {
              console.error('Error fetching RSVP items:', error);
          });

    },
    toggleFilters(){
      this.showfilters = !this.showfilters;
    },
    getStyle(rsvpReg){

      let style = (rsvpReg.entry ? 'background-color:lightgreen;' : '') + (rsvpReg.membershipId != '' ? 'color:blue;' : '');

      return style;

    },
    setRSVP(name, id, rsvpType){
        this.selectedRSVPName = name;
        this.selectedRSVPType = rsvpType;
        this.rsvpId = id;

        this.successmessage='';

        this.fetchMemberList();
    },
    async fetchMemberList() {
      // Reference the Firestore collection and fetch the documents

      let db = firebase.firestore();

      db.collection('members')
        .where("status", "==", "1")
        .get()
        .then(querySnapshot => {
          const memberList = [];
          querySnapshot.forEach(doc => {
          // Extract the RSVP data from each document
              const rsvp = {
                rsvpRegId: doc.id,
                ...doc.data()
              };

              if(rsvp.year == undefined){
                rsvp.year = '';
              }

              memberList.push(rsvp);
              });
              this.memberList = memberList;
              this.records = JSON.parse(JSON.stringify(this.memberList));
          })
          .catch(error => {
          console.error(error);
        });
    },
    rowDataBound: function(args) {

        if (args.data['entry']) {
            args.row.classList.add('entryBackgroud');
        } 
        
        if (args.data['membershipId']) {
            args.row.classList.add('blueText');
        } 
    },
    getNow(isDate){

        const today = new Date();
        const date = today.getFullYear()+'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const dateTime = date +' '+ time;

        if(isDate)
        {
          return date;
        }
        return dateTime;
    },
    toolbarClick(args) {
        switch (args.item.id) {
           
            case 'membershipList_excelexport':
              
                this.$refs.membershipList.excelExport(this.getExcelExportProperties());
                break;
            case 'membershipList_pdfexport':

                if(this.selectedRSVPType == 'General Type'){
                  // this.$refs.registrationList.getColumns()[6].visible = false;
                  // this.$refs.registrationList.getColumns()[9].visible = false;
                }
                else if(this.selectedRSVPType == 'General Type'){

                }
                else if(this.selectedRSVPType == 'General Type'){

                }
                else if(this.selectedRSVPType == 'General Type'){

                }
              

               
                this.$refs.membershipList.pdfExport(this.getPdfExportProperties());
                break;
        }
        
    },
    pdfExportComplete(args) {

     

    },
    getDate(){
        let date = '';
        date += ((new Date()).getMonth().toString()) + '/' + ((new Date()).getDate().toString());
        return date += '/' + ((new Date()).getFullYear().toString());
    },
    getExcelExportProperties: function() {
        return {
            header: {
                headerRows: 7,
                rows: [
                    {
                        index: 1,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 5, value: this.selectedRSVPName, style: { fontColor: '#C25050', fontSize: 25, hAlign: 'Center', bold: true } }
                        ]
                    },
                    {
                        index: 3,
                        cells: [
                            /* tslint:disable-next-line:max-line-length */
                            { index: 1, colSpan: 2, value: 'Tamil sneham', style: { fontColor: '#C67878', fontSize: 15, bold: true } },
                            { index: 5, value: 'DATE', style: { fontColor: '#C67878', bold: true }, width: 150 }
                        ]
                    },
                    {
                        index: 4,
                        cells: [
                            { index: 1, colSpan: 2, value: 'Tampa' },
                            { index: 5, value: this.getNow(false), width: 150 }
                        ]
                    },
                ]
            },

            footer: {
                footerRows: 5,
                rows: [
                    /* tslint:disable-next-line:max-line-length */
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] },
                    { cells: [{ colSpan: 6, value: ' ', style: { fontColor: '#C67878', hAlign: 'Center', bold: true } }] }
                ]
            },
            
            fileName: "exceldocument.xlsx"
        };
    },
    getPdfExportProperties: function() {
        return {
          pageOrientation: 'Landscape',
            header: {
                fromTop: 0,
                height: 120,
                contents: [
                    {
                        type: 'Text',
                        value: this.selectedRSVPName,
                        position: { x: 280, y: 0 },
                        style: { textBrushColor: '#C25050', fontSize: 25 },
                    },
                    {
                        type: 'Text',
                        value: 'PRINT DATE',
                        position: { x: 500, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: this.getNow(false),
                        position: { x: 600, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 50 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 70 },
                        style: { textBrushColor: '#C67878', fontSize: 10 },
                    }, {
                        type: 'Text',
                        value: ' ',
                        position: { x: 500, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 600, y: 90 },
                        style: { textBrushColor: '#000000', fontSize: 10 },
                    },
                    {
                        type: 'Text',
                        value: 'Tamil sneham',
                        position: { x: 20, y: 30 },
                        style: { textBrushColor: '#C67878', fontSize: 20 }
                    },
                ]
            },
            footer: {
                fromBottom: 50,
                height: 100,
                contents: [
                    {
                        type: 'Text',
                        value: ' ',
                        position: { x: 250, y: 20 },
                        style: { textBrushColor: '#C67878', fontSize: 14 }
                    },
                ]
            },
            
            fileName: "pdfdocument.pdf"
        };
    } 
  },
};
</script>

<style>
    .table{
        background-color:white !important;
    }

    .blueText td{
      color: blue !important;
    }

    .entryBackgroud{
      background-color: lightgreen;
    }
</style>