<script setup>


</script>

<template>
    <section class="section section-shaped section-lg my-0" style="margin-top: 100px !important;">
        <div class="container">
            <div class="">
                <div class="col-lg-12">
                    <card shadow header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5"
                        class="border-0">

                        <template>
                            <div>
                                <h3>Membership Registration</h3>
                            </div>
                            <p>
                                The membership is applicable for the period 1st Jan 2025 to 31st Dec 2025 <br/>
                               
                            </p>
                            <form class="form">
                                <!-- <base-dropdown class="form-group mb-6 col-lg-6 input-group">
                                    <base-input slot="title" type="default" class="dropdown-toggle mb-3" alternative readonly placeholder="Membership Type"
                                        style="background-color: white;" id="type" v-model="membershipType" :error="typeError">
                                    </base-input>
                                    <a class="dropdown-item" href="#" @click="setType('Family')">Family</a>
                                    <a class="dropdown-item" href="#" @click="setType('Individual')">Individual</a>
                                </base-dropdown> -->
                                <div class="col-lg-6 mb-3">
                                    <select class="form-select" ref="type" id="type" aria-label="" v-model="membershipType" @change="setType()">
                                        <!-- <option selected></option> -->
                                        <option value="Family">Family</option>
                                        <option value="Individual">Individual</option>
                                    </select>
                                </div>


                                <div class="row">
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="fname">
                                       <base-input class="col-lg-12" form-control label="First Name" labelClasses="col-lg-12" :error="firstNameError"
                                    id="fname" v-model="fname" required></base-input>  
                                    </div>
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="lname">
                                         <base-input  class="col-lg-12" label="Last Name" form-control labelClasses="col-lg-12" :error="lastNameError"
                                    id="lname" v-model="lname"
                                    required></base-input>
                                    </div>

                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="email">
                                         <base-input  class="col-lg-12" label="Email" form-control labelClasses="col-lg-12" :error="emailError"
                                    id="email" v-model="email" @blur="validateEmail"
                                    required></base-input>
                                    </div>
                                   
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="telephone">
                                        <base-input  class="col-lg-12" label="Phone (For Communication)" form-control labelClasses="col-lg-12" :error="phoneNumberError"
                                    id="telephone" v-model="telephone" @blur="validatePhoneNumber"
                                    required></base-input>
                                    </div>
                                    
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="spouse">
                                        <base-input  class="col-lg-12" label="Spouse name" form-control labelClasses="col-lg-12"
                                    id="spouse" v-model="spouse"
                                    ></base-input>
                                    </div>
                                    
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="spouseemail">
                                         <base-input  class="col-lg-12" label="Spouse Email" form-control labelClasses="col-lg-12"
                                    id="spouseemail" v-model="spouseemail"
                                    ></base-input>
                                    </div>
                                   
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="password">
                                        <base-input  class="col-lg-12" label="Password" form-control labelClasses="col-lg-12" type="password" :error="passwordError"
                                    id="password" v-model="password"
                                    required></base-input>
                                    </div>
                                    
                                    <div class="col-md-6 col-sm-12 form-group mb-3" data-for="retypePassword">
                                        <base-input  class="col-lg-12" label="Retype Password" form-control labelClasses="col-lg-12" type="password" :error="retypePasswordError"
                                    id="retypePassword" v-model="retypePassword"
                                    required></base-input>
                                    </div>
                                    

                                    <div v-if="membershipType == 'Family'" class="col-lg-6"> 
                                        <div class="col-lg-12 form-group mb-3" data-for="familyMembers">
                                            <label style="">Total Family Members<span>*</span></label><br/>
                                       
                                             <!-- <base-dropdown class="form-group col-md-12 input-group">
                                                <base-input slot="title" type="default" class="dropdown-toggle mb-3 col-lg-12" alternative readonly 
                                                    style="background-color: white;" id="familyMembers" v-model="familyMembers" :error="familyMemberError">
                                                </base-input>
                                                <a class="dropdown-item" href="#" @click="setMemberCount(1)">1</a>
                                                <a class="dropdown-item" href="#" @click="setMemberCount(2)">2</a>
                                                <a class="dropdown-item" href="#" @click="setMemberCount(3)">3</a>
                                                <a class="dropdown-item" href="#" @click="setMemberCount(4)">4</a>
                                             
                                            </base-dropdown> -->

                                            <div class="col-lg-6 mb-3" style="width: 50%;">
                                                <select class="form-select" ref="type" id="type" aria-label="" v-model="familyMembers">
                                                    <!-- <option selected></option> -->
                                                    <option v-for="(id,index) in 4" :key="id" :selected="[ index == 0 ? 'selected' : '']" :value="id">{{ id }}</option>
                                                
                                                </select>
                                            </div>
                                        </div>
                                       
                                    </div>
                                 
                                    <div v-if="membershipType == 'Family'" class="mb-3 col-lg-6"> &nbsp;</div>
                                    <br />
                                    <h3 style="padding-left:15px; padding-top:32px;">Fee to be Paid: $ {{ fee }}</h3>
                                    <br />
                                    <br />
                                    <div style="width:100%" id="paypal-button-container"></div>
                                    <!-- <h4>{{ successmessage}}</h4> -->
                                    <!-- <base-button type="submit" @click="importSTP" class="my-4">Import STP</base-button> -->
                                    <!-- <base-button class="mb-3 col-lg-6" type="primary" @click="submitRSVP()">Submit</base-button> -->
                                </div>
                                
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <modal :show="showPopup" @close="closePopup" type="notice">
            <h2>{{ successmessage }}</h2>

            <base-button class="mb-3 col-lg-6" type="primary" @click="closePopup()">Ok</base-button>

        </modal>
    </section>
</template>

<script>

import firebase from '../Firebase.js';
import Modal from '../components/Modal.vue';
const QRCode = require('qrcode-svg');

export default {

    data() {
        return {
           
            fname:'',
            lname:'',
            email:'',
            telephone:'',
            spouse:'',
            spouseemail:'',
            password:'',
            retypePassword:'',
           
            
            isValid:true,
            member:false,
            member_id:'',
            membershipId:'',
            membershipEmail:'',
            firstNameError: '',
            lastNameError:'',
            emailError:'',
            phoneNumberError:'',
            spouseEmailError:'',
            passwordError:'',
            retypePasswordError:'',
            familyMemberError:'',
            typeError:'',
            dobError:'',
            gradeError:'',
            regTypeError:'',
            successmessage:'',
            membershipType:'',
            familyMembers:'',
            rsvpId:'QnZThdwtmiofmZympVVn',
            rsvp: {},
            registrationId: '',
            active: false,
            showPopup: false,
            fee:0,
        };
    },
    methods: {
        setType(){
            // this.membershipType = type;
            this.fee = this.membershipType == 'Family' ? 100 : 50;
        },
        closePopup(){

            this.showPopup = false;
            this.$router.push('/RSVPRegistration');
        },
        importSTP(){
            this.fname = 'Arunkumar';
            this.lname = 'Ganesan';
            this.email = 'miscemailusa@gmail.com';
            this.telephone = '7276563254';
            this.spouse = '';
            this.spouseemail = '';
            this.password = 'qwerty123';
            this.membershipType = 'Family';
            this.familyMembers = 4;
            this.fee = 0;
            this.member_id = 0;

            this.member = false;
            this.membershipId = '';

            let details = {};

            this.submitMembershipRegistration(details);

        },
        async submitMembershipRegistration(details) {
            // Add the data to Firestore
            const db = firebase.firestore();

            let mem = null;

            if(this.member){
               mem = await db.collection('members')
                    .doc(this.membershipId)
                    .update({
                        fname: this.fname,
                        lname: this.lname,
                        email: this.email,
                        telephone: this.telephone,
                        spouse: this.spouse,
                        spouseemail: this.spouseemail,
                        password: this.password,
                        type: this.membershipType,
                        year: 2025,
                        status: '1',
                        familyMembers: this.familyMembers,
                        paypalId: details.id,
                        payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                        payerEmail: details.payer.email_address,
                        paidDateTime: details.create_time,
                        paidStatus: details.status,
                        paidAmount: details.purchase_units[0].amount.value,
                        renewedOn: firebase.firestore.Timestamp.fromDate(new Date(this.getNow(true)))
                    });

                    // .then((obj) => {
                    console.log('Membership updated successfully!');

                    // this.membershipId = mem.id;

                    firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                        .catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        if (errorCode == 'auth/weak-password') {
                            console.log('The password is too weak.');
                        } else {
                            console.log(errorMessage);
                        }
                        console.log(error);
                    });

                   
                    // })
                    // .catch(error => {
                    //     console.error('Error updating RSVP:', error);
                    // });
            }
            else{

                this.member_id = this.getNow(false);

                mem = await db.collection('members')
                .add({
                    fname: this.fname,
                    lname: this.lname,
                    email: this.email,
                    telephone: this.telephone,
                    spouse: this.spouse,
                    spouseemail: this.spouseemail,
                    password: this.password,
                    type: this.membershipType,
                    year: 2025,
                    status: '1',
                    member_id: this.member_id,
                    familyMembers:this.familyMembers,
                    paypalId: details.id,
                    payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
                    payerEmail: details.payer.email_address,
                    paidDateTime: details.create_time,
                    paidStatus: details.status,
                    paidAmount: details.purchase_units[0].amount.value,
                    registeredOn: firebase.firestore.Timestamp.fromDate(new Date(this.getNow(true))),
                    renewedOn: firebase.firestore.Timestamp.fromDate(new Date(this.getNow(true))),
                    lifeTime: false,
                });

                // .then((obj) => {

                    this.membershipId = mem.id
                    console.log(this.membershipId);

                    firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                        .catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        if (errorCode == 'auth/weak-password') {
                            console.log('The password is too weak.');
                        } else {
                            console.log(errorMessage);
                        }
                        console.log(error);
                    });
                console.log('Member registration saved successfully!');
                // Reset the form after successful submission

                // this.sendEmail();
                // this.resetForm();
                // this.successmessage = 'The Registration is successful, confirmation mail has been sent to your Email';
                // })
                // .catch(error => {
                // console.error('Error saving Member registration:', error);
                // });
            }

            
            let mail = await this.sendEmail();
            this.successmessage = 'The Membership have been activated, confirmation mail has been sent to ' + this.email;
            this.showPopup = true;
            this.resetForm();

            
        },
        // async fetchRSVP() {
        //     // Get a reference to the Firestore database
        //     const db = firebase.firestore();

        //     console.log(this.rsvpId);

        //     await db.collection('rsvp')
        //         .doc(this.rsvpId)
        //         .get()
        //         .then(doc => {
        //         if (doc.exists) {
        //             this.rsvp = { id: doc.id, ...doc.data() };
        //         } else {
        //             console.log('RSVP item not found');
        //         }
        //         })
        //         .catch(error => {
        //         console.error('Error fetching RSVP item:', error);
        //         });

        //     if(this.rsvp.active){
        //         this.active = true;
        //         // if(!this.rsvp.freeEvent && !this.payPalInit){
        //         //     //this.initPayPalButton();
        //         // }
        //     }
        //     else{
        //         this.active = false;
        //     }
            
           
        // },
        // async saveRSVP(details){

        //     // Get a reference to the Firestore database
        //     const db = firebase.firestore();

        //     let reg = null;
        //     let firstName = this.fname;
        //     let lastName = this.lname;
        //     let emailTo = this.email;
        //     let phoneNo = this.telephone;
        //     this.rsvp.adults = this.familyMembers;

        //     console.log('RSVPPPPPP', this.rsvp);
           
        //     reg = await db.collection('RSVPRegistration').add({
        //         firstName: firstName,
        //         lastName: lastName,
        //         email: emailTo,
        //         phoneNumber: phoneNo,
        //         adults: this.familyMembers,
        //         kids: 0,
        //         familyMembers: this.familyMembers,
        //         rsvpId: this.rsvpId,
        //         paypalId: details.purchase_units[0].payments.captures[0].id,
        //         payerName: details.payer.name.given_name + ' ' + details.payer.name.surname,
        //         payerEmail: details.payer.email_address,
        //         paidDateTime: details.create_time,
        //         paidStatus: details.status,
        //         paidAmount: 0,
        //         membershipId: this.membershipId,
        //         rsvpName: this.rsvp.name,
        //         rsvpEventDate: this.rsvp.eventDate,
        //         firstNameSport: '',
        //         lastNameSport: '',
        //         emailSports: '',
        //         phoneNumberSports: '',
        //         firstNameStudent: '',
        //         lastNameStudent: '',
        //         studentGrade: '',
        //         parentName: '',
        //         emailYLP: '',
        //         phoneNumberYLP: '',
        //         competition: '',
        //         firstNameTalent: '',
        //         lastNameTalent: '',
        //         ageGroup: '',
        //         parentNameTalent: '',
        //         emailTalent: '',
        //         phoneNumberTalent: '',
        //         participants: '',
        //         teamNameVolley : '',
        //         tournament : '',
        //         captainNameVolley : '',
        //         emailVolley : '' ,
        //         phoneNumberVolley : '',
        //     });
            
        //     this.registrationId = reg.id;

        //     this.setTotalRegistered();

        //     // this.sendEmail();

            
        //     // this.successmessage = 'The Registration is successful, confirmation mail has been sent to ' + emailTo;
        //     return null;

        // },
        // async setTotalRegistered() {

        //     const db = firebase.firestore();

        //     try {

        //     // Query the members collection based on the email or phone number
        //     const querySnapshot = await db.collection('RSVPRegistration')
        //         .where('rsvpId', '==', this.rsvpId)
        //         .get();

        //     if(!querySnapshot.empty){
            
        //         let count = 0;
        //         let totalAdults = 0;
        //         let totalKids = 0;
        //         let totalMembers = 0;
        //         let totalNonMembers = 0;

        //         querySnapshot.forEach(async doc => {
        //         // Extract the RSVP data from each document
        //             count++;

        //             const rsvp = {
        //                 rsvpRegId: doc.id,
        //                 ...doc.data()
        //                 };
                    

        //                 totalAdults += rsvp.adults == "" ? 0 : parseInt(rsvp.adults);
        //                 totalKids += rsvp.kids == "" ? 0 : parseInt(rsvp.kids);
        //                 rsvp.membershipId != '' ? totalMembers++ : totalNonMembers++; 
                    
        //             });

        //             this.rsvp.totalReg = count;
        //             this.rsvp.totalAdults = totalAdults;
        //             this.rsvp.totalKids = totalKids;
        //             this.rsvp.totalMember = totalMembers;
        //             this.rsvp.totalNonMember = totalNonMembers;

        //             await db.collection('rsvp')
        //                 .doc(this.rsvp.id)
        //                 .update(this.rsvp)
        //                 .then(() => {
        //                     console.log('RSVP updated successfully!');
        //                 })
        //                 .catch(error => {
        //                     console.error('Error updating RSVP:', error);
        //                 });
                
        //         return true;
        //     }
        //     else{
            
        //         return false;
        //     }

        //     } catch (error) {
        //         console.error('Error checking member registration:', error);
        //         return false;
        //         }
        // },
        async sendEmail(){
            

            // Get a reference to the Firestore database
            const db = firebase.firestore();

            // console.log('Registration ifd ', this.registrationId);
            // console.log('Membershiop ifd ', this.membershipId);

            // console.log('Membershiop ifd3333 ', this.membershipId);

            const qrCode = new QRCode({
                content: btoa(this.membershipId), // 'https://example.com/confirmation', // Replace with your confirmation URL or data
                container: 'svg',
                join: true
             }).svg();

            await db.collection('email').add({
                from: 'contact@tamilsneham.org',
                subject: 'Member Registration Confirmation',
                email: this.email,
                userName: this.fname + ' ' + this.lname,
                qrCode: qrCode,
                registrationId: this.membershipId,
                totalPaid: this.fee,
                member_id: this.member_id,
                year: 2025,
                mailType: 1,
                
            });

            
            return null;
            
        },
        formatDate(date) {

            if(date == undefined) return '';

            // const options = { day: "2-digit", month: "short", year: "numeric" };
            // const formattedDate = new Date(date).toLocaleDateString(
            //   "en-US",
            //   options
            // );

            const formattedDate = new Date(date).toUTCString();

            // Split the formatted date into day, month, and year parts
            const [weekday, day, month, year, time, type] = formattedDate.split(" ");

            // Convert the month abbreviation to uppercase
            const capitalizedMonth = month.toUpperCase();

            // Set the formatted date with uppercase month abbrevia tion and desired format
            return `${day} ${capitalizedMonth} ${year}`;
        },
        resetForm() {
            // Reset the form fields
           
                this.fname = '';
                this.lname = '';
                this.email = '';
                this.telephone = '';
                this.spouse = '';
                this.spouseemail = '';
                this.password = '';
                this.retypePassword = '';
                this.member_id = '';
                this.membershipId = '';
                this.familyMembers = '';
                this.membershipType = '';
            
        },
        getNow(isDate){

            const today = new Date();

            const date = today.getFullYear() +'/'+ String(today.getMonth()+1).padStart(2, '0') +'/'+ String(today.getDate()).padStart(2, '0');
            const shortDate = today.getFullYear().toString().slice(-2) +''+ String(today.getMonth()+1).padStart(2, '0') +''+ String(today.getDate()).padStart(2, '0');
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;

            if(isDate){
                return dateTime;
            }
           
            return parseInt(shortDate +''+ today.getMinutes() +''+ today.getMilliseconds());
        },
        calculateFee(){
            return this.fee;
        },
        validateSpouseEmail() {
            console.log('Email validation-', this.email);

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.email)) {
                console.log('Email validation-true', this.email);
                this.spouseEmailError = '';
                this.isValid=true;
                //this.validateEmailAndPhoneNumber(this.email, this.telephone);
            } else {
                console.log('Email validation-false', this.email);
                this.spouseEmailError = 'Please enter a valid email address';
                this.isValid=false;
            }

            
        },
        validateEmail() {
            console.log('Email validation-', this.email);

            const regex = /^[^@]+@\w+(\.\w+)+\w$/;

            if (regex.test(this.email)) {
                console.log('Email validation-true', this.email);
                this.emailError = '';
                this.isValid=true;
                this.validateEmailAndPhoneNumber(this.email, this.telephone);
            } else {
                console.log('Email validation-false', this.email);
                this.emailError = 'Please enter a valid email address';
                this.isValid=false;
            }

            
        },
        validatePhoneNumber() {
            console.log('Phone validation-', this.telephone);

            const regex = /^\d{10}$/;

            if (regex.test(this.telephone)) {
                console.log('Phone validation-true', this.telephone);
                this.phoneNumberError = '';
                this.isValid=true;
                // this.validateEmailAndPhoneNumber(this.email, this.telephone);
               
            } else {
                console.log('Phone validation-false', this.telephone);
                this.phoneNumberError = 'Please enter a valid Phone number without any special characters';
                this.isValid=false;
            }

            
        },
        async validateEmailAndPhoneNumber(email, phoneNumber) {

            //const registeredAlready = await this.checkRegistration(email);

            //if(!registeredAlready){
            const memberExists = await this.checkMemberExistence(email, phoneNumber);

            if (memberExists) {
                // Perform additional actions if the member exists
                console.log('Member exists');
                this.member = true;
                //this.verifyAndSendOTP(this.membershipEmail, this.membershipId);

            } else {
                // Handle the case where the member does not exist
                console.log('Member not exists');
                this.otpId = '';
                this.member = false;
            }
            //}

            
        },
        async checkMemberExistence(email, phoneNumber) {

            const db = firebase.firestore();

            try {

                console.log('Email:', email);
                console.log('Phone', phoneNumber);

            // Query the members collection based on the email or phone number
            const querySnapshot = await db.collection('members')
                .where('email', '==', email == "" ? "_____" : email.toLowerCase())
                .get();
            
            const querySnapshot1 = await db.collection('members')
                .where('telephone', '==', phoneNumber == "" ? "000000" : phoneNumber )
                .get();

            if(!querySnapshot.empty || !querySnapshot1.empty){

                if(!querySnapshot.empty){

                    console.log(querySnapshot.docs[0].id);

                    this.membershipId = querySnapshot.docs[0].id;
                    this.membershipEmail = querySnapshot.docs[0].data().email;
                    this.member_id = querySnapshot.docs[0].data().member_id;

                    console.log('Memmbership detail '+ this.membershipId + ' --' + this.membershipEmail);
                }
                else{
                    console.log(querySnapshot1.docs[0].id);

                    this.membershipId = querySnapshot1.docs[0].id;
                    this.membershipEmail = querySnapshot1.docs[0].data().email;
                    this.member_id = querySnapshot1.docs[0].data().member_id;

                    console.log('Membership detail '+ this.membershipId + ' --' + this.membershipEmail);
                }

                return true;
            }
            else{
                return false;
            }

            // return !querySnapshot.empty || !querySnapshot1.empty; // Return true if a matching member document is found, false otherwise


            } catch (error) {
            console.error('Error checking member existence:', error);
            return false;
            }
        },
        initPayPalButton() {
            // eslint-disable-next-line no-undef
            paypal
                .Buttons({
                createOrder: (data, actions) => {
                
                    this.typeError = '';
                    this.firstNameError = '';
                    this.lastNameError = '';
                    this.emailError = '';
                    this.phoneNumberError = '';
                    // this.spouseEmailError = '';
                    this.passwordError = '';
                    this.retypePasswordError = '';
                    this.familyMemberError = '';
                    
                    this.isValid = true;

                    if(this.membershipType == ''){this.typeError = 'Membership type is required'; this.isValid=false}
                    if(this.fname == ''){this.firstNameError = 'First name required'; this.isValid=false}
                    if(this.lname == ''){this.lastNameError = 'Last name required'; this.isValid=false}
                    if(this.email == ''){this.emailError = 'Email is required'; this.isValid=false}
                    if(this.telephone == ''){this.phoneNumberError = 'Phone number is required'; this.isValid=false}
                    // if(this.spouseemail == ''){this.spouseEmailError = 'Spouse Email is required'; this.isValid=false}
                    if(this.password == ''){this.passwordError = 'Password is required'; this.isValid=false}
                    if(this.retypePassword == ''){this.retypePasswordError = 'Password is required'; this.isValid=false}

                    if(this.password != this.retypePassword){this.passwordError = 'Password doesnot match'; this.retypePasswordError=' '; this.isValid=false;}
                    
                    if(this.membershipType == 'Family'){
                        if(this.familyMembers == ''){
                            this.familyMemberError = 'Family members is required'; 
                            this.isValid=false;
                        }
                    }

                    if(!this.isValid){return;}

                    return actions.order.create({
                    purchase_units: [
                        {
                        amount: {
                            value: this.fee.toString(),
                        },
                        },
                    ],
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((details) => {
                    // Handle successful payment here
                    console.log(details);

                    this.submitMembershipRegistration(details);

                    });
                },
                onError: (err) => {
                    // Handle errors here
                    console.error(err);
                },
                })
                .render("#paypal-button-container");
        },
        importData(){

            const db = firebase.firestore();

            // Read data from the JSON file
            const data = require('../data.json');

            // Function to import data into Firestore
            const importData1 = async () => {
            try {
                // Loop through each document in the JSON data array
                for (const document of data) {
                // Add the document to Firestore collection

                document.email = document.email.toLowerCase();
                document.spouseemail = document.spouseemail.toLowerCase();

                await db.collection('members').add(document);
                }

                console.log('Data import completed.');
            } catch (error) {
                console.error('Error importing data:', error);
            }
            };

            importData1();

        }
        },
        mounted() {
            console.log("Mounted");
            this.initPayPalButton();
            // this.fetchRSVP();
       
        },
};
</script>

<style scoped></style>
